export const concerts = [
  // {
  //   city: "Astana",
  //   date: "25 aprel",
  //   address1: "Rebernaya 69",
  //   address2: "Qayim Muhammedov Ko'chasi, 5",
  //   url: "https://sxodim.com/astana/event/solnyy-stendap-koncert-mirshakara-fayzulloeva",
  // },
  // {
  //   city: "Almata",
  //   date: "27 aprel",
  //   address1: "Almata Central Stand-up Club",
  //   address2: "Kabanbay Batyr Ko'chasi, 71",
  //   url: "https://sxodim.com/almaty/event/solnyy-stendap-koncert-mirshakara-fayzulloeva",
  // },
  // {
  //   city: "Shimkent",
  //   date: "28 aprel",
  //   address1: '"Nash bar"',
  //   address2: "Prospekt Abaya, 28/3",
  //   url: "https://sxodim.com/shymkent/event/solnyy-stendap-koncert-mirshakara-fayzulloeva",
  // },
  // {
  //   city: "Ausburg",
  //   date: "10 may",
  //   address1: "YOLO TOAST",
  //   address2: "Ludwigstraße 13, 86152",
  //   url: "https://t.me/sahnastandup_bot?start=66052584a42a3_uz",
  // },
  // {
  //   city: "Frankfurt",
  //   date: "12 may",
  //   address1: "Kulturhaus Frankfurt",
  //   address2: "Pfingstweidstraße 2, 60316",
  //   url: "https://t.me/sahnastandup_bot?start=66052675b10dd_uz",
  // },
  // {
  //   city: "Vena",
  //   date: "27 sentabr",
  //   address1: "Grnd Restaurant",
  //   address2: "Kärntner Str. 61, 1010",
  //   url: "https://fienta.com/de/mirshakar-fayzulloyev-venada?9eef77da140c030e68ea797e109c614c",
  // },
  // {
  //   city: "Praga",
  //   date: "29 sentabr",
  //   address1: "KKAFE",
  //   address2: "Střelecký ostrov 336, 110 00 Praha 1",
  //   url: "https://buytickets.at/118/1372887",
  // },
  // {
  //   city: "Varshava",
  //   date: "3 oktabr",
  //   address1: "Secret location",
  //   address2: "Stanisława Noakowskiego 16, 10785",
  //   url: "https://fienta.com/pl/mirshakar-fayzulloyev-varshavada-101776",
  // },
  // {
  //   city: "Berlin",
  //   date: "6 oktabr",
  //   address1: "KARAKAS",
  //   address2: "Kurfürstenstraße 9, 10785",
  //   url: "https://fienta.com/de/mirshakar-fayzulloyev-berlinda-101769",
  // },
  // {
  //   city: "Parij",
  //   date: "10 oktabr",
  //   address1: "Broadway comedy club",
  //   address2: "25 Bd de Bonne Nouvelle, 75002",
  //   url: "https://fienta.com/fr/mirshakar-fayzulloyev-parijda",
  // },
  // {
  //   city: "Istanbul",
  //   date: "31 may",
  //   address1: "Dervish han Hobyar",
  //   address2: "Dervişoğlu Sk. No:8, 34112",
  //   url: "https://mirshakar-fayzulloyev.ticketscloud.org/",
  // },
];
