import React, { useLayoutEffect, useRef } from 'react'
import char from "../assets/img/main_character.png";
import dream from "../assets/img/nimadreambor.png";
import gsap from 'gsap';
import { concerts } from '../utils/CONSTANTS';

function Header() {
    const ref = useRef();

    const getConcertInfo = (() => {
        let index = 1;
        return () => {
            if (index === concerts.length) {
                index = 0;
            }
            return concerts[index++];
        };
    })();

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {

            gsap.set(".info_location", {
                y: -100,
            });
            gsap.set(".info_address_1", {
                y: -100,
            });
            gsap.set(".info_address_2", {
                y: -100,
            });

            const tl = gsap.timeline({ repeat: -1 });

            tl.set(".info_location", {
                y: -100,
                duration: 0,
            });
            tl.set(".info_address_1", {
                y: -100,
                duration: 0,
            });
            tl.set(".info_address_2", {
                y: -100,
                duration: 0,
            });

            tl.to(".info_location", {
                y: 0,
            });
            tl.to(".info_address_1", {
                y: 0,
                delay: -0.3,
            });
            tl.to(".info_address_2", {
                y: 0,
                delay: -0.3,
            });

            tl.to(".info_location", {
                y: 100,
                duration: 0.3,
                delay: 0.8,
            });
            tl.to(".info_address_1", {
                y: 100,
                delay: -0.15,
            });
            tl.to(".info_address_2", {
                y: 100,
                delay: -0.25,
            });

            // show main then hide address 2

            tl.eventCallback("onRepeat", () => {
                if (concerts.length > 0) {
                    const info = getConcertInfo();
                    gsap.set(".info_location", {
                        innerHTML: info.city,
                    });
                    gsap.set(".info_address_1", {
                        innerHTML: `${info.address1}<br />${info.address2}`,
                    });
                }
            })


        }, ref);

        return () => ctx.revert();
    }, []);


    return (
        <header ref={ref}>
            <div className="container wide">
                <div className="head_content">
                    <div className="desc">
                        <div className="heading">
                            <h1>
                                Mirshakar <br /> Fayzulloyev
                            </h1>
                            <span>stand-up konsert</span>
                            <div className='naming'>
                                <img src={dream} />
                            </div>
                        </div>
                        {
                            concerts.length > 0 ? (
                                <div className="info">
                                    <div className='info_line'>
                                        <h2 className='info_location'>{concerts[0].city}</h2>
                                    </div>
                                    <div className='info_line'>
                                        <p className='info_address_1'>
                                            {concerts[0].address1}
                                            <br />
                                            {concerts[0].address2}
                                        </p>
                                    </div>
                                </div>
                            ) : null
                        }
                        <div className="info">
                            <a href="#afisha" className='btn'>afisha</a>
                        </div>
                    </div>
                    <img src={char} className="main_character" alt="mirshakar" />
                </div>
            </div>
        </header>
    )
}

export default Header